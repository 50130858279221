import React from "react";

const DarkBlueButton = ({
  name,
  handleClick,
  className,
  textStyle,
  type,
  disabled = false,
}: {
  name: string;
  handleClick?: (e?: any) => void;
  className?: string;
  textStyle?: string;
  type?: "button" | "submit" | "reset" | undefined;
  disabled?: boolean;
}) => {
  return (
    <button
      className={`rounded-lg opacity-80 bg-sbTextHover px-4 py-2 text-white hover:bg-queueHover mr-3 focus:outline-none disabled:cursor-not-allowed ${
        className ? className : ""
      }`}
      disabled={disabled}
      type={type ? type : "button"}
      onClick={handleClick}
    >
      <p className={`${textStyle ? textStyle : ""}`}>{name}</p>
    </button>
  );
};

export default DarkBlueButton;
