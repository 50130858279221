import React, { useRef, useEffect, useState } from 'react';

interface VideoCoverImageProps {
  videoUrl: string;
  frameTime?: number; // Frame time in seconds (default: 2 seconds)
  className:string;
}

const VideoCoverImage: React.FC<VideoCoverImageProps> = ({ videoUrl, frameTime = 2 ,className}) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [imageSrc, setImageSrc] = useState<string>('');
  
  // Function to extract a frame from the video URL
  const extractFrame = async () => {
    const video = document.createElement('video');
    video.src = videoUrl;
    video.crossOrigin = 'anonymous'; // Avoid CORS issues
    video.currentTime = frameTime;

    return new Promise<void>((resolve) => {
       // Wait until the video can be played
    video.addEventListener('canplay', () => {
      video.currentTime = frameTime; // Set the desired frame time
    });

    video.addEventListener('seeked', () => {
      const canvas = canvasRef.current;
      if (canvas) {
        const context = canvas.getContext('2d');
        if (context) {
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          context.drawImage(video, 0, 0, canvas.width, canvas.height);

          const imageData = canvas.toDataURL('image/png');
          setImageSrc(imageData); // Set the image source
          resolve();
        }
      }
    });
    });
  };

  useEffect(() => {
    extractFrame();
  }, [videoUrl]);

  return (
    <div className='w-full'>
      {imageSrc ? (
        <img  className={className} src={imageSrc} alt="Video Cover" />
      ) : (
        <div className='w-full h-full flex justify-center items-center'>
          <div className="loader border-t-transparent border-4 border-blue-500 rounded-full w-5 h-5 animate-spin"></div>
        </div>
      )}
      {/* Hidden canvas element for extracting the frame */}
      <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
    </div>
  );
};

export default VideoCoverImage;