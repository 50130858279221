export const DashboardHover = ({ style }: { style?: string }) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={style}
  >
    <path
      d="M5 10.5H7C9 10.5 10 9.5 10 7.5V5.5C10 3.5 9 2.5 7 2.5H5C3 2.5 2 3.5 2 5.5V7.5C2 9.5 3 10.5 5 10.5Z"
      //   stroke="#0F3CBE"
      stroke="#0F3CBE"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 10.5H19C21 10.5 22 9.5 22 7.5V5.5C22 3.5 21 2.5 19 2.5H17C15 2.5 14 3.5 14 5.5V7.5C14 9.5 15 10.5 17 10.5Z"
      stroke="#0F3CBE"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 22.5H19C21 22.5 22 21.5 22 19.5V17.5C22 15.5 21 14.5 19 14.5H17C15 14.5 14 15.5 14 17.5V19.5C14 21.5 15 22.5 17 22.5Z"
      stroke="#0F3CBE"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 22.5H7C9 22.5 10 21.5 10 19.5V17.5C10 15.5 9 14.5 7 14.5H5C3 14.5 2 15.5 2 17.5V19.5C2 21.5 3 22.5 5 22.5Z"
      stroke="#0F3CBE"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ResourceHover = ({ style }: { style?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="22"
    height="25"
    viewBox="0,0,300,150"
  >
    <g
      fill="#0F3CBE"
      fill-rule="nonzero"
      stroke="none"
      stroke-width="1"
      stroke-linecap="butt"
      stroke-linejoin="miter"
      stroke-miterlimit="10"
      stroke-dasharray=""
      stroke-dashoffset="0"
      font-family="none"
      font-weight="none"
      font-size="none"
      text-anchor="none"
    >
      <g transform="scale(6.33333,5.33333)">
        <path d="M8.5,8c-2.4675,0 -4.5,2.0325 -4.5,4.5v23.90625h0.01563c-0.02911,1.87068 1.49276,3.59375 3.48633,3.59375h29.04492c1.87,0 3.55591,-1.16701 4.21289,-2.91797l5.00781,-13.33203v-0.00391c0.85596,-2.24444 -0.86676,-4.74609 -3.26953,-4.74609h-0.49805v-1.5c0,-2.4675 -2.0325,-4.5 -4.5,-4.5h-13.45703l-4.46875,-3.72461c-0.00065,0 -0.0013,0 -0.00195,0c-0.98816,-0.82328 -2.2331,-1.27539 -3.51953,-1.27539zM8.5,11h7.55273c0.58557,0 1.14982,0.20536 1.59961,0.58008l4.88672,4.07227c0.26976,0.22477 0.60981,0.3478 0.96094,0.34766h14c0.8465,0 1.5,0.6535 1.5,1.5v1.5h-25.54687c-1.87,0 -3.55591,1.16701 -4.21289,2.91797l-2.24023,5.96484v-15.38281c0,-0.8465 0.6535,-1.5 1.5,-1.5zM13.45313,22h29.04492c0.39986,0 0.6094,0.30515 0.4668,0.67773c-0.00131,0.00325 -0.00262,0.00651 -0.00391,0.00977l-5.00977,13.33984c-0.22102,0.58904 -0.7743,0.97266 -1.4043,0.97266h-29.04492c-0.39772,0 -0.60838,-0.3029 -0.46875,-0.67383c0.00065,-0.00065 0.0013,-0.0013 0.00195,-0.00195l5.01367,-13.35156c0.22101,-0.58904 0.7743,-0.97266 1.4043,-0.97266z"></path>
      </g>
    </g>
  </svg>
);

export const ScheduleHover = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 2.5V5.5"
      stroke="#0F3CBE"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 2.5V5.5"
      stroke="#0F3CBE"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.5 9.59H20.5"
      stroke="#0F3CBE"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21 9V17.5C21 20.5 19.5 22.5 16 22.5H8C4.5 22.5 3 20.5 3 17.5V9C3 6 4.5 4 8 4H16C19.5 4 21 6 21 9Z"
      stroke="#0F3CBE"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.6947 14.2H15.7037"
      stroke="#0F3CBE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.6947 17.2H15.7037"
      stroke="#0F3CBE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.9955 14.2H12.0045"
      stroke="#0F3CBE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.9955 17.2H12.0045"
      stroke="#0F3CBE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.29431 14.2H8.30329"
      stroke="#0F3CBE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.29431 17.2H8.30329"
      stroke="#0F3CBE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DoctorHover = ({ style }: { style?: string }) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={style}
  >
    <path
      d="M10.1786 2H11.625H12.6529C13.8841 2 14.8229 3.10177 14.6276 4.31736L13.3419 12.3174C13.186 13.287 12.3493 14 11.3672 14H5.13281C4.15074 14 3.31398 13.287 3.15815 12.3174L1.87243 4.31735C1.67707 3.10177 2.61591 2 3.84709 2H4.875H6.32143"
      stroke="#0F3CBE"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M8.06067 14.2647V20C8.06067 21.6569 9.40381 23 11.0607 23H16.4999C18.1568 23 19.4999 21.6569 19.4999 20V9.5"
      stroke="#0F3CBE"
      strokeWidth="1.5"
    />
    <circle cx="19.5" cy="8" r="2.25" stroke="#0F3CBE" strokeWidth="1.5" />
    <rect
      x="9.28162"
      y="0.856934"
      width="2.13423"
      height="2.25"
      rx="1.06712"
      fill="#0F3CBE"
    />
    <rect
      x="5.10095"
      y="0.856934"
      width="2.13423"
      height="2.25"
      rx="1.06712"
      fill="#0F3CBE"
    />
  </svg>
);

export const PatientHover = ({ style }: { style?: string }) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={style}
  >
    <path
      d="M9.16 11.37C9.06 11.36 8.94 11.36 8.83 11.37C6.45 11.29 4.56 9.34 4.56 6.94C4.56 4.49 6.54 2.5 9 2.5C11.45 2.5 13.44 4.49 13.44 6.94C13.43 9.34 11.54 11.29 9.16 11.37Z"
      stroke="#0F3CBE"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.41 4.5C18.35 4.5 19.91 6.07 19.91 8C19.91 9.89 18.41 11.43 16.54 11.5C16.46 11.49 16.37 11.49 16.28 11.5"
      stroke="#0F3CBE"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.15997 15.06C1.73997 16.68 1.73997 19.32 4.15997 20.93C6.90997 22.77 11.42 22.77 14.17 20.93C16.59 19.31 16.59 16.67 14.17 15.06C11.43 13.23 6.91997 13.23 4.15997 15.06Z"
      stroke="#0F3CBE"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.34 20.5C19.06 20.35 19.74 20.06 20.3 19.63C21.86 18.46 21.86 16.53 20.3 15.36C19.75 14.94 19.08 14.66 18.37 14.5"
      stroke="#0F3CBE"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const AppointmentsHover = ({ style }: { style?: string }) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={style}
  >
    <path
      d="M9.31 15.2L10.81 16.7L14.81 12.7"
      stroke="#0F3CBE"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 6.5H14C16 6.5 16 5.5 16 4.5C16 2.5 15 2.5 14 2.5H10C9 2.5 8 2.5 8 4.5C8 6.5 9 6.5 10 6.5Z"
      stroke="#0F3CBE"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 4.52C19.33 4.7 21 5.93 21 10.5V16.5C21 20.5 20 22.5 15 22.5H9C4 22.5 3 20.5 3 16.5V10.5C3 5.94 4.67 4.7 8 4.52"
      stroke="#0F3CBE"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const HelpHover = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 7.75V13"
      stroke="#0F3CBE"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.08 8.58003V15.42C21.08 16.54 20.48 17.58 19.51 18.15L13.57 21.58C12.6 22.14 11.4 22.14 10.42 21.58L4.47998 18.15C3.50998 17.59 2.90997 16.55 2.90997 15.42V8.58003C2.90997 7.46003 3.50998 6.41999 4.47998 5.84999L10.42 2.42C11.39 1.86 12.59 1.86 13.57 2.42L19.51 5.84999C20.48 6.41999 21.08 7.45003 21.08 8.58003Z"
      stroke="#0F3CBE"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 16.2V16.3"
      stroke="#0F3CBE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SettingsHover = ({ style }: { style?: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={style}
  >
    <path
      d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
      stroke="#0F3CBE"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 12.88V11.12C2 10.08 2.85 9.22 3.9 9.22C5.71 9.22 6.45 7.94 5.54 6.37C5.02 5.47 5.33 4.3 6.24 3.78L7.97 2.79C8.76 2.32 9.78 2.6 10.25 3.39L10.36 3.58C11.26 5.15 12.74 5.15 13.65 3.58L13.76 3.39C14.23 2.6 15.25 2.32 16.04 2.79L17.77 3.78C18.68 4.3 18.99 5.47 18.47 6.37C17.56 7.94 18.3 9.22 20.11 9.22C21.15 9.22 22.01 10.07 22.01 11.12V12.88C22.01 13.92 21.16 14.78 20.11 14.78C18.3 14.78 17.56 16.06 18.47 17.63C18.99 18.54 18.68 19.7 17.77 20.22L16.04 21.21C15.25 21.68 14.23 21.4 13.76 20.61L13.65 20.42C12.75 18.85 11.27 18.85 10.36 20.42L10.25 20.61C9.78 21.4 8.76 21.68 7.97 21.21L6.24 20.22C5.33 19.7 5.02 18.53 5.54 17.63C6.45 16.06 5.71 14.78 3.9 14.78C2.85 14.78 2 13.92 2 12.88Z"
      stroke="#0F3CBE"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
