import { useEffect, useState } from "react";
import Header from "../components/organisms/Header";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Table from "../components/organisms/Table";
import { docListHeaders } from "../assets/data/headers";
import Welcome from "../components/molecules/Welcome";
import DashboardCard from "../components/atoms/DashboardCard";
import { useHospDocData } from "../lib/contexts/HospitalDoctorContext";
import Loader from "../components/molecules/Loader";
import { getRouteSegment } from "../lib/utils/funcs";
import { useUserData } from "../lib/contexts/UserContext";
import { getDoctorListByHospitalId } from "../lib/apis/doctor";
import BarGraph from "../components/organisms/charts/BarGraph";
import LineGraph from "../components/organisms/charts/LineGraph";
import DarkBlueButton from "../components/atoms/buttons/DarkBlueButton";
import WhiteButton from "../components/atoms/buttons/WhiteButton";
import DashboardCard2 from "../components/atoms/DashboardCard2";
import { MdOutlineMoreTime } from "react-icons/md";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import { toast } from "react-toastify";
import { getCookie, setCookie } from "../lib/utils/cookies";
import apiClient from "../lib/apis/fetch";
import { baseURL } from "../lib/utils/constants";
// ..
AOS.init();

const Dashboard = () => {
  const navigate = useNavigate();
  const { userData } = useUserData();
  console.log(userData);

  const currnetYear = new Date().getFullYear().toString()

  const { hospitalID, setHospitalID, doctorsData, setDoctorsData } =
    useHospDocData();

  const id = getRouteSegment(1);
  const [customBtn, setCustomBtn] = useState(false);
  const [load, setLoad] = useState(true);
  const [customDates, setCustomDates] = useState({
    startDate: "",
    endDate: "",
    year: currnetYear,
  });
  const hospital_id = getRouteSegment(1);
  const getData = async (formattedDate: string) => {
    const currentDate = new Date();
    // Calculate the first day of the month, 6 months ago
    const sixMonthsAgo = new Date(currentDate.setMonth(currentDate.getMonth() - 11));
    sixMonthsAgo.setDate(1);
    const response = await apiClient.get(`${baseURL}/analytics/booking`, {
      params: {
        hospital_id: hospital_id,
        start_date:  sixMonthsAgo.toISOString().split('T')[0],
        end_date: formattedDate,
      },
    });
    if (response?.status === 200) {
      setCookie("bookingOverview", JSON.stringify(response.data.result), 30);
      setWaitingTime({
        then: response.data.result.avg_wait_time_last_50_minutes,
        now: response.data.result.avg_wait_time_first_50_minutes,
      });
      setTimeout(() => {
        setLoad(false);
      }, 500);
    }
    return response;
  };
  function compareDates(date1: string, date2: string) {
    // Parse the date strings into Date objects
    const date1Obj = new Date(
      `${date2.slice(0, 4)}/${date1.slice(3, 5)}/${date1.slice(0, 2)}`
    );
    const date2Obj = new Date(date2);

    // Compare the dates
    if (date1Obj.getTime() === date2Obj.getTime()) {
      return 0; // Dates are equal
    } else if (date1Obj.getTime() < date2Obj.getTime()) {
      return -1; // date1 is earlier than date2
    } else {
      return 1; // date1 is later than date2
    }
  }
  
  const getAvgWaitTime = async () => {
    try{
      const response = await apiClient.get(`${baseURL}/analytics/waitTimeStats`, {
      params: {
        hospital_id: hospital_id,
      },
    });
    if (response?.status === 200) {
      setCookie("avgWaitTimeStats", JSON.stringify(response.data.result), 1);
      setAvgWaitTime(response.data.result)
      setTimeout(() => {
        setLoad(false);
      }, 500);
    }
    }catch(error){
      console.log(error)
    }
  }

  const getDailyData = async () => {
    const response = await apiClient.get(`${baseURL}/analytics/current-month`, {
      params: {
        hospital_id: hospital_id,
      },
    });
    if (response?.status === 200) {
      setCookie("dailyOverview", JSON.stringify(response.data.result), 1);
      setTimeout(() => {
        setLoad(false);
      }, 500);
    }
    return response;
  };

  useEffect(() => {
    if (
      getCookie("bookingOverview") !== undefined &&
      getCookie("dailyOverview") !== undefined
    ) {
      setLoad(false);
    }
    const today = new Date();

    // Get the year, month, and day
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");

    // Format the date as "YYYY-MM-DD"
    const formattedDate = `${year}-${month}-${day}`;

    if (
      getCookie("bookingOverview") === undefined ||
      compareDates(
        formattedDate.split("-")[1],
        JSON.parse(String(getCookie("bookingOverview"))).monthly_bookings[
          JSON.parse(String(getCookie("bookingOverview"))).monthly_bookings
            .length - 1
        ].month.split("/")[0]
      ) === 1
    ) {
      getData(formattedDate);
    } else {
      setWaitingTime({
        then: JSON.parse(String(getCookie("bookingOverview")))
          .avg_wait_time_last_50_minutes,
        now: JSON.parse(String(getCookie("bookingOverview")))
          .avg_wait_time_first_50_minutes,
      });
    }

    if (
      getCookie("dailyOverview") === undefined ||
      compareDates(
        formattedDate,
        JSON.parse(String(getCookie("dailyOverview"))).daily_counts[
          JSON.parse(String(getCookie("dailyOverview"))).daily_counts.length - 1
        ].day
      ) === 1
    ) {
      getDailyData();
    }
    
    // AVERAGE WAITING TIME STATS
    if (getCookie("avgWaitTimeStats") === undefined) {
      getAvgWaitTime();
    } else {
      setAvgWaitTime(JSON.parse(String(getCookie("avgWaitTimeStats"))))
      setTimeout(() => {
        setLoad(false);
      }, 500);
    }
  }, [hospital_id]);

  const [overviewStartMonth, setOverviewStartMonth] = useState("");
  const [overviewEndMonth, setOverviewEndMonth] = useState("");
  const [overviewYear, setOverviewYear] = useState(currnetYear);
  const [overview, setOverview] = useState({
    confirm: true,
    type: "monthly",
  });
  const [noshow, setNoShow] = useState("month");
  const [waitingTime, setWaitingTime] = useState({
    then: "0",
    now: "0",
  });
  const [avgWaitTime, setAvgWaitTime] = useState({
    "avg_wait_time_minutes" : 0,
    "highest_avg_wait_time_minutes" : 0,
    "lowest_avg_wait_time_minutes" : 0,
  })
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  useEffect(() => {
    setHospitalID(id);
  }, [id]);

  useEffect(() => {
    const fetchdoctorsData = async () => {
      const res = await getDoctorListByHospitalId(hospitalID);
      setDoctorsData(undefined);
      if (res?.status === 200) {
        console.log("doctorsData", res.data.result);

        setDoctorsData(res.data.result);
      } else console.log(res);
    };

    fetchdoctorsData();
  }, [hospitalID, userData]);
  console.log(waitingTime);

  return (
    <div className="flex flex-col w-full">
      {!load && userData !== undefined && (
        <>
          <Header header value={"Dashboard"} />
          <div className="flex flex-col lg:flex-row mt-7 px-7 lg:pr-0">
            <div className="flex flex-col w-full lg:w-1/2">
              <Welcome />
              <div
                className="flex bg-[#335FE4] rounded-lg p-2 text-white gap-4 mt-6 "
                data-aos="zoom-in"
                data-aos-duration="1000"
              >
                <div className="flex justify-end w-[38%] p-2 xl:p-6">
                  <div
                    className="flex flex-col justify-center items-end"
                    data-aos="slide-left"
                    data-aos-duration="1000"
                  >
                    <p className="font-thin text-sm md:text-md">Hignest Avg </p>
                    <h1 className="font-normal text-lg md:text-3xl xl:text-4xl mt-1 text-right">
                      {/* {waitingTime.then === null ? 0 : waitingTime.then} min */}
                      {(+avgWaitTime.highest_avg_wait_time_minutes).toFixed(2)} min
                    </h1>
                  </div>
                </div>
                <div className="flex justify-center w-[40%] md:w-[24%] bg-[#2651d1] rounded-lg p-4 z-20">
                  <div className="flex flex-col justify-center items-center">
                    <MdOutlineMoreTime className="w-8 h-8" />
                    <p className="text-xs mt-2 font-thin text-center">
                      Queue waiting time
                    </p>
                  </div>
                </div>
                <div className="flex justify-start w-[38%] p-2 xl:p-6">
                  <div
                    className="flex flex-col justify-center items-start"
                    data-aos="slide-right"
                    data-aos-duration="1000"
                  >
                    <p className="font-thin  text-sm md:text-md">Lowest Avg</p>
                    <h1 className="font-normal text-lg md:text-3xl xl:text-4xl mt-1">
                      {/* {waitingTime.now === null ? 0 : waitingTime.now} min */}
                      {(+avgWaitTime.lowest_avg_wait_time_minutes).toFixed(2)} min
                    </h1>
                  </div>
                </div>
              </div>
              <div className="flex flex-col my-5">
                <div className="flex flex-col md:flex-row mb-4 space-y-[10px] md:space-x-[10px] md:space-y-0">
                  <DashboardCard
                    hospitalID={String(hospitalID)}
                    doctor_id={String(userData?.doctor_id)}
                    name={"Today's Bookings"}
                  />
                  <DashboardCard2
                    value={
                      doctorsData === undefined
                        ? "0"
                        : String(doctorsData?.length)
                    }
                    name={"Doctors available today"}
                  />
                </div>
              </div>
            </div>
            <div
              className="lg:ml-5 lg:mr-7 lg:w-1/2 h-[93%] bg-white rounded-lg"
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              <div className="flex flex-row justify-between items-center bg-white px-3 md:px-5 py-4 rounded-lg">
                <p className="text-dark text-sm lg:text-base font-bold">
                  Doctor List
                </p>

                <WhiteButton
                  name=" View All"
                  className="hover:bg-black hover:text-white  bg-white opacity-100 w-24 text-sm !mr-0"
                  handleClick={() => navigate("/" + hospitalID + "/doctors")}
                />
              </div>
              {doctorsData === undefined ? (
                <div className="bg-white flex justify-center items-center h-40">
                  <p className="text-center">No Doctors Found.</p>
                </div>
              ) : (
                <div className="w-full overflow-x-auto">
                  <Table
                    type="doclist"
                    headers={docListHeaders}
                    docData={doctorsData.sort(
                      (a, b) =>
                        moment(a.created_at).valueOf() -
                        moment(b.created_at).valueOf()
                    )}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="px-7 flex flex-col md:flex-row justify-between gap-5 mb-4 mt-8 lg:mt-0">
            <div
              className=" w-full lg:w-1/2 bg-white rounded-lg px-4 py-5 lg:px-5 lg:py-6 pb-2 lg:pb-2"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div className="flex flex-col lg:flex-row justify-between pl-4 items-center">
                <h1 className="font-semibold text-xl">Bookings overview</h1>
                <div className="flex gap-0 mt-4 lg:mt-0 w-full md:w-auto">
                  <WhiteButton
                    name="Custom"
                    className={
                      customBtn === true || overview.type === "custom"
                        ? "bg-black hover:bg-black text-white  opacity-100 w-full md:w-28 !md:px-0"
                        : "hover:bg-black hover:text-white  bg-white opacity-100  w-full md:w-28 !md:px-0"
                    }
                    handleClick={() => {
                      setCustomBtn(true);
                      setOverview({
                        ...overview,
                        type: "custom",
                      });
                    }}
                  />
                  <WhiteButton
                    name="Daily"
                    className={
                      overview.type === "daily"
                        ? "bg-black hover:bg-black text-white  opacity-100 w-full md:w-20 !px-0"
                        : "hover:bg-black hover:text-white  bg-white opacity-100  w-full md:w-20 !px-0"
                    }
                    handleClick={() => {
                      setOverview({
                        ...overview,
                        type: "daily",
                      });
                      setCustomBtn(false);
                    }}
                  />
                  <WhiteButton
                    name="Monthly"
                    className={
                      overview.type === "monthly"
                        ? "bg-black hover:bg-black text-white  opacity-100 w-full md:w-20 !px-0"
                        : "hover:bg-black hover:text-white  bg-white opacity-100  w-full md:w-20 !px-0"
                    }
                    handleClick={() => {
                      setOverview({
                        ...overview,
                        type: "monthly",
                      });
                      setCustomBtn(false);
                    }}
                  />
                </div>
              </div>
              {customBtn && (
                <div
                  className="p-4 flex flex-col items-center bg-[#FAFAFA] mt-4"
                  data-aos="fade-up"
                  data-aos-duration="500"
                  data-aos-offset="0"
                >
                  <h1 className="font-semibold">Choose custom overview</h1>
                  <div className="flex gap-2 mt-4">
                    {[`${Number(currnetYear) - 2}`, `${Number(currnetYear) - 1}`, currnetYear].map((year) => (
                      <WhiteButton
                        name={year}
                        className={
                          (year === String(customDates.year) &&
                            overview.confirm === true) ||
                          (year === String(overviewYear) &&
                            overview.confirm === false)
                            ? "hover:bg-black text-white  bg-black opacity-100"
                            : "hover:bg-black hover:text-white  bg-white opacity-100"
                        }
                        handleClick={() => {
                          setOverview({
                            ...overview,
                            confirm: false,
                          });
                          setOverviewYear(year);
                        }}
                      />
                    ))}
                  </div>
                  <div>
                    <div className="w-full">
                      <p className="text-modalText text-md mb-1 mt-8 lg:mt-2 font-bold">
                        Choose Start Month
                      </p>

                      <div className="grid grid-cols-6 justify-evenly mt-2 gap-4">
                        {monthNames.map((month) => (
                          <WhiteButton
                            name={month}
                            className={
                              (month ===
                                String(
                                  monthNames[
                                    Number(
                                      customDates.startDate.split("-")[1]
                                    ) - 1
                                  ]
                                ) &&
                                overview.confirm === true) ||
                              (month === String(overviewStartMonth) &&
                                overview.confirm === false)
                                ? "hover:bg-[#A3B9FF]  bg-[#A3B9FF] opacity-100 rounded-full text-white border-none !p-1 !px-2 !m-0 md:h-12 w-max md:w-20"
                                : "bg-transparent hover:bg-[#A3B9FF]  opacity-100 rounded-full hover:text-white border-none !p-1 !px-2 !m-0 md:h-12 w-max md:w-20"
                            }
                            handleClick={() => {
                              setOverview({
                                ...overview,
                                confirm: false,
                              });
                              setOverviewStartMonth(month);
                            }}
                          />
                        ))}
                      </div>
                    </div>
                    <div className="w-full">
                      <p className="text-modalText text-md mb-1 mt-8 lg:mt-2 font-bold">
                        Choose End Month
                      </p>

                      <div className="grid grid-cols-6 justify-evenly mt-2 gap-4">
                        {monthNames.map((month) => (
                          <WhiteButton
                            name={month}
                            className={
                              (month ===
                                String(
                                  monthNames[
                                    Number(customDates.endDate.split("-")[1]) -
                                      1
                                  ]
                                ) &&
                                overview.confirm === true) ||
                              (month === String(overviewEndMonth) &&
                                overview.confirm === false)
                                ? "hover:bg-[#A3B9FF]  bg-[#A3B9FF] opacity-100 rounded-full text-white border-none !p-1 !px-2 !m-0 md:h-12 w-max md:w-20"
                                : "bg-transparent hover:bg-[#A3B9FF]  opacity-100 rounded-full hover:text-white border-none !p-1 !px-2 !m-0 md:h-12 w-max md:w-20"
                            }
                            handleClick={() => {
                              setOverview({
                                ...overview,
                                confirm: false,
                              });
                              setOverviewEndMonth(month);
                            }}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-2 mt-8 w-full">
                    <WhiteButton
                      name="Cancel"
                      className="hover:bg-black hover:text-white  bg-white opacity-100 w-full text-sm py-4 !mr-0 "
                      handleClick={() => {
                        setCustomBtn(false);
                        setOverview({
                          ...overview,
                          confirm: true,
                        });
                        setOverviewStartMonth("");
                        setOverviewEndMonth("");
                        if (
                          customDates.endDate === "" &&
                          customDates.startDate === ""
                        ) {
                          setOverview({
                            ...overview,
                            type: "monthly",
                          });
                        }
                      }}
                    />

                    <DarkBlueButton
                      name="Choose"
                      className="w-full text-sm !mr-0 py-4"
                      handleClick={() => {
                        if (
                          overviewStartMonth !== "" &&
                          overviewEndMonth !== "" &&
                          monthNames.indexOf(overviewStartMonth) <
                            monthNames.indexOf(overviewEndMonth)
                        ) {
                          setOverview({
                            confirm: true,
                            type: "custom",
                          });
                          setCustomDates({
                            startDate: `${overviewYear}-${
                              monthNames.indexOf(overviewStartMonth) + 1
                            }-01`,
                            endDate: `${overviewYear}-${
                              monthNames.indexOf(overviewEndMonth) + 1
                            }-01`,
                            year: overviewYear,
                          });
                          setCustomBtn(false);
                        } else {
                          toast.error("Invalid Range");
                        }
                      }}
                    />
                  </div>
                </div>
              )}
              {!customBtn && (
                <LineGraph type={overview.type} customDates={customDates} />
              )}
            </div>
            <div
              className=" w-full lg:w-1/2 bg-white rounded-lg px-4 py-5 lg:px-5 lg:py-6 pb-2 lg:pb-2"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div className="flex flex-col lg:flex-row md:justify-between pl-4  items-center lg:items-start">
                <h1 className="font-semibold text-xl">No shows overview</h1>
                <div className="flex gap-2 mt-4 lg:mt-0 w-full md:w-auto">
                  <WhiteButton
                    name="Monthly"
                    className={
                      noshow === "month"
                        ? "bg-black hover:bg-black text-white  opacity-100 w-full md:w-20 !px-0"
                        : "hover:bg-black hover:text-white  bg-white opacity-100  w-full md:w-20 !px-0"
                    }
                    handleClick={() => {
                      setNoShow("month");
                    }}
                  />
                  <WhiteButton
                    name="Daily"
                    className={
                      noshow === "daily"
                        ? "bg-black hover:bg-black text-white  opacity-100 w-full md:w-20 !px-0"
                        : "hover:bg-black hover:text-white  bg-white opacity-100  w-full md:w-20 !px-0"
                    }
                    handleClick={() => {
                      setNoShow("daily");
                    }}
                  />
                </div>
              </div>
              <BarGraph type={noshow} />
            </div>
          </div>
        </>
      )}
      {(load || userData === undefined) && <Loader />}
    </div>
  );
};

export default Dashboard;
