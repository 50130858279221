import React from "react";
import ProfilePicture from "../atoms/ProfilePicture";
import { useHospDocData } from "../../lib/contexts/HospitalDoctorContext";
import { useUserData } from "../../lib/contexts/UserContext";

const HospDetails = () => {
  const { hospData } = useHospDocData();
  const { userData } = useUserData();

  return (
    <div className="flex flex-row items-center">
      {hospData?.logo === "test" || hospData?.logo === "" ? (
        <ProfilePicture
          username={hospData.hospital_name}
          className={"!w-20 !h-20"}
        />
      ) : (
        <img
          className={`w-20 h-20 rounded-full`}
          src={hospData?.logo}
          alt="hospital-logo"
        ></img>
      )}
      <div className="ml-6 space-y-1 md:pr-24">
        <p className="text-xl font-medium text-dark">
          {hospData?.hospital_name}
        </p>
        <p className="text-sm text-dark">
          Timings : {hospData?.hospital_timing}
        </p>
        <p className="text-sm text-dark">
          {hospData?.hospital_contact_number.substring(2)} | {userData?.email}
        </p>
      </div>
    </div>
  );
}

export default HospDetails;
