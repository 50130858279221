import React, { Suspense } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import Sidebar from "./components/organisms/Sidebar";
import Signin from "./screens/Signin";
import Dashboard from "./screens/Dashboard";
import ErrorBoundary from "./components/organisms/ErrorBoundary";
import Loader from "./components/molecules/Loader";
import Resources from "./screens/Resources";

// importing pages lazily so that when a user hits that route, it dowunloads that page only
const Doctors = React.lazy(() => import("./screens/Doctors"));
const EachDoctor = React.lazy(() => import("./screens/EachDoctor"));
const Appointments = React.lazy(() => import("./screens/Appointments"));
const Settings = React.lazy(() => import("./screens/Settings"));
const DocDetails = React.lazy(() => import("./screens/DocDetails"));
const Patients = React.lazy(() => import("./screens/Patients"));
const ChooseHosp = React.lazy(() => import("./screens/ChooseHosp"));
const Reports = React.lazy(() => import("./screens/Reports"));
const AppointmentBooking = React.lazy(
  () => import("./screens/AppointmentBooking")
);
const Relations = React.lazy(() => import("./screens/Relations"));
const Support = React.lazy(() => import("./screens/Support"));

const AppRoutes = () => {
  const location = useLocation();

  return (
    <div className="flex flex-col lg:flex-row">
      {location.pathname !== "/" && location.pathname !== "/admin" && (
        <Sidebar />
      )}
      <ErrorBoundary>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/" element={<Signin />} />
            <Route path="admin" element={<ChooseHosp />} />
            <Route path=":hospital_id">
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="resources" element={<Resources />} />
                <Route path="doctors" element={<Doctors />} />
                <Route path="doctors/doc/:mapping_id" element={<EachDoctor />} />
                <Route path="doctors/:mapping_id" element={<DocDetails />} />

                <Route path="appointments" element={<Appointments />} />
                <Route
                  path="appointments/book-an-appointment"
                  element={<AppointmentBooking />}
                />

                <Route path="patients" element={<Patients />} />
                <Route path="patients/:patient_id" element={<Reports />} />
                <Route
                  path="patients/relations/:patient_id"
                  element={<Relations />}
                />

              <Route path="settings" element={<Settings />} />
              <Route path="support" element={<Support />} />
            </Route>
          </Routes>
        </Suspense>
      </ErrorBoundary>
    </div>
  );
};

export default AppRoutes;
