import axios from "axios";
import { header } from "../utils/header";
import { ResourcesType, updateHospital } from "../utils/types";
import { baseURL } from "../utils/constants";
import apiClient from "./fetch";

//Get Hospitals by User ID
export const getResourcesByHospitalId= async (hospital_id: string) => {
  try {
    const res = await apiClient.get(`${baseURL}/resource/${hospital_id}`);
    return res;
  } catch (error: any) {
    if (error.response || error.response.status === 403) {
      return error.response;
    } else {
      console.error(error);
    }
  }
};

export const insertResources = async (hospital_id:string,resource_url:string,category:string,seqnum:number) => {
  try {
    const res = await apiClient.post(`${baseURL}/resource/insert`,
        {
            hospital_id:hospital_id,
            resource_url:resource_url,
            category:category,
            seqnum:seqnum,
        }
    );
    return res;
  } catch (error: any) {
    if (error.response || error.response.status === 403) {
      return error.response;
    } else {
      console.error(error);
    }
  }
};

export const deleteResources = async (hospital_id:string,resource_id:string,seqnum:number) => {
  try {
    const res = await apiClient.post(`${baseURL}/resource/delete`,
        {
            hospital_id:hospital_id,
            resource_id:resource_id,
            seqnum:seqnum,
        }
    );
    return res;
  } catch (error: any) {
    if (error.response || error.response.status === 403) {
      return error.response;
    } else {
      console.error(error);
    }
  }
};

export const updateResources = async (data:ResourcesType[]) => {
  try {
    const res = await apiClient.post(`${baseURL}/resource/update`,
       {
        resourceData:data
       }
    );
    return res;
  } catch (error: any) {
    if (error.response || error.response.status === 403) {
      return error.response;
    } else {
      console.error(error);
    }
  }
};

export const updateResourcesURL = async (resource_id:string,resource_url:string) => {
  try {
    const res = await apiClient.post(`${baseURL}/resource/updateurl`,
       {
        resource_id:resource_id,
        resource_url:resource_url
       }
    );
    return res;
  } catch (error: any) {
    if (error.response || error.response.status === 403) {
      return error.response;
    } else {
      console.error(error);
    }
  }
};


